import React, { useRef } from "react";
import PropTypes from "prop-types";

const centerElement = `
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const formStyle = `
  ${centerElement}
  width: 90%;
  max-width: 480px;
  @media all and (min-width: 960px) {
    width: 40%;
    font-size: 1.5rem;
  }
  label {
    display: block;
    padding-bottom: 5px;
    margin-bottom: 1rem;
    input, textarea {
      display: block;
      border-radius: 7px;
      margin-top: 10px;
      width: 100%;
      height: 3rem;
    }
    textarea {
      height: 6rem;
    }
  }
  button {
    color: currentColor;
    display: block;
    margin-left: auto;
    margin-right: 0;
    background-color: transparent;
    border: 2px solid currentColor;
    border-radius: 10px;
    padding: 0.6rem 1.5rem;
    cursor: pointer;
  }
`;

const SignupForm = ({ onSubmit }) => {
  const nameRef = useRef();
  const phoneRef = useRef();
  const emailRef = useRef();
  const companyRef = useRef();
  const messageRef = useRef();

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit(
      nameRef.current.value,
      phoneRef.current.value,
      emailRef.current.value,
      companyRef.current.value,
      messageRef.current.value
    );
  };

  return (
    <form css={formStyle} onSubmit={handleSubmit}>
      <label>
        Name
        <input type="text" required ref={nameRef} />
      </label>
      <label>
        Phone
        <input type="tel" required ref={phoneRef} />
      </label>
      <label>
        Email
        <input type="email" required ref={emailRef} />
      </label>
      <label>
        Company
        <input type="text" required ref={companyRef} />
      </label>
      <label>
        Message
        <textarea required ref={messageRef} />
      </label>
      <button type="submit">Submit</button>
    </form>
  );
};

SignupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default SignupForm;
