import React from "react";
import PropTypes from "prop-types";
import {
  WideContainer,
  SlimSection,
  SectionTitle
} from "../components/Section";
import { Button } from "../components/Buttons";

import { colors as c } from "../style";

const actionBannerContainerstyle = `
  margin: 0 2rem 0 2rem;
  align-items: center;
`;

const rowStyle = `
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  @media all and (min-width: 700px) {
    flex-flow: row wrap;
  }

  > div, > h2 {
    flex-grow: 1;
  }
`;

const ActionBanner = ({ title, buttonText, onButtonClick }) => (
  <WideContainer bgColor={`${c.bg_section_alt}`}>
    <SlimSection css={actionBannerContainerstyle}>
      <div css={rowStyle}>
        <SectionTitle>{title}</SectionTitle>
        <div
          css={`
            text-align: center;
          `}
        >
          <Button large onClick={onButtonClick}>
            {buttonText}
          </Button>
        </div>
      </div>
    </SlimSection>
  </WideContainer>
);

ActionBanner.propTypes = {
  title: PropTypes.string,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func
};

export default ActionBanner;
