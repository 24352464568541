import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { Hero, Guarantees, WhatWeDo, Companies } from "../components/main";
import TrustBoxWidget from "../components/trustbox";
import { ResponsiveWidthContainer } from "../components/Section";
import Modal from "../components/Modal";
import SignupForm from "../components/SignupForm";
import ActionBanner from "../components/ActionBanner";

export const SignupPageTemplate = ({
  hero,
  whatwedo,
  guarantees,
  companies,
  signup
}) => {
  const [showModal, setShowModal] = useState(false);

  const submitForm = (name, phone, email, company, message) => {
    alert(`${name}\n${phone}\n${email}\n${company}\n${message}\n`);
    setShowModal(false);
  };

  return (
    <>
      <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)}>
        <SignupForm onSubmit={submitForm} />
      </Modal>
      <Hero {...hero} onButtonClick={() => setShowModal(true)} />
      <Guarantees {...guarantees} />
      <Companies items={companies} />
      <WhatWeDo {...whatwedo} />
      <ActionBanner {...signup} onButtonClick={() => setShowModal(true)} />
      <ResponsiveWidthContainer>
        <TrustBoxWidget widget="carousel" />
      </ResponsiveWidthContainer>
    </>
  );
};

SignupPageTemplate.propTypes = {
  hero: PropTypes.object,
  whatwedo: PropTypes.object,
  guarantees: PropTypes.object,
  companies: PropTypes.array,
  signup: PropTypes.object
};

const SignupPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout headerOpts={{ phoneOnly: true }} location={location}>
      <SignupPageTemplate {...frontmatter} />
    </Layout>
  );
};

SignupPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  }),
  location: PropTypes.object
};

export default SignupPage;

export const signupPageQuery = graphql`
  query SignupPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        guarantees {
          title
          reviewsimage {
            img {
              childImageSharp {
                fluid(maxWidth: 550) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            alt
          }
          cross
          tick
        }
        headerimage {
          img {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
        }
        meta {
          content
          name
        }
        hero {
          heading
          subheading
          image {
            text
            img {
              childImageSharp {
                fluid(maxWidth: 550) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          buttontext
        }
        companies {
          companyname
          img {
            childImageSharp {
              fixed(width: 350) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
        }
        whatwedo {
          title
          items {
            title
            text
            img {
              childImageSharp {
                fixed(width: 250) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
        }
        signup {
          title
          buttonText
        }
      }
    }
  }
`;
